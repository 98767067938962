
import { authUser, logInPassword, registration } from '@/api/authModule';
import { activeFlow, setCurrentStage, startFlow } from '@/api/flowSession';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import BaseCheckBox from '@/components/baseComponents/BaseCheckBox.vue';
import BaseInput from '@/components/baseComponents/BaseInput.vue';
import BaseInputPassword from '@/components/baseComponents/BaseInputPassword.vue';
import BaseCertificates from '@/components/baseComponents/BaseCertificates.vue';
import GoogleAuthComponent from '@/components/landingAndRegistrationComponents/GoogleAuthComponent.vue';
import TimerRedirect from '@/components/landingAndRegistrationComponents/TimerRedirect.vue';
import TitleDescriptionUniversal from '@/components/portal/TitleDescriptionUniversal.vue';
import { getNextPage, enterToTheFlow } from '@/utils/redirectUtils';
import { getUserSettings } from '@/utils/updateInfo';
import { universalRegExpChecker } from '@/utils/validationUtils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters } from 'vuex';

@Component({
  components: {
    BaseButton,
    BaseInput,
    BaseInputPassword,
    BaseCheckBox,
    TitleDescriptionUniversal,
    BaseCertificates,
    GoogleAuthComponent,
    TimerRedirect,
  },
  computed: {
    ...mapGetters('user', ['isRegistrationCompleted']),
    ...mapGetters('flowStages', ['getCurrentFlow']),
  },
})
export default class RegistrationPage extends Vue {
  public isRegistrationCompleted!: boolean;
  public getCurrentFlow!: string;

  password = '';
  isPreloaderShow = false;
  isCheckBoxActive = false;
  errorMessageEmail = '';
  passwordDescription = '';
  errors = {
    email: false,
    password: false,
  };
  isEmailValid = false;
  isPasswordValid = false;
  authStatus = '';
  timeout: null | number = null;
  isTimerRedirectShow = false;

  get email(): string {
    return this.$store.state.user.email;
  }
  set email(value: string) {
    this.$store.commit('user/setEmail', value);
  }
  get correctFields(): boolean {
    return this.isEmailValid && this.isPasswordValid && this.isCheckBoxActive;
  }

  onMountAndTypingValidation(fieldName: string): void {
    if (fieldName === 'email') 
      this.isEmailValid = universalRegExpChecker('email', this.email);

    if (fieldName === 'password') {
      this.isPasswordValid = universalRegExpChecker('password', this.password);
      this.errors.password = false;
      this.passwordDescription = this.isPasswordValid
        ? ''
        : 'Password minimum of 8 characters: must contain uppercase & lowercase, at least 1 number, special characters optional';
    }
  }
  checkField(fieldName: string): void {
    switch (fieldName) {
      case 'email':
        this.errors.email = !universalRegExpChecker('email', this.email);
        this.errorMessageEmail = 'Please type your email correctly';

        if (this.isEmailValid) 
          this.checkEmailStatus();
        break;
      case 'password':
        this.errors.password = !universalRegExpChecker('password', this.password);
        break;
      default:
        break;
    }
  }
  async checkEmailStatus(): Promise<void> {
    this.authStatus = await authUser(this.email);
    this.isTimerRedirectShow = false;

    if (this.authStatus === 'IncompleteUser' || this.authStatus === 'CompleteUser') {
      this.errorMessageEmail = 'This email is already registered. Please, use “I have an account” link below';
      this.errors.email = true;
      this.isTimerRedirectShow = true;
    }
  }
  async nextButtonAction(): Promise<void> {
    try {
      this.isPreloaderShow = true;
      await this.checkEmailStatus();

      if (this.authStatus === 'NewUser') {
        await registration({
          email: this.email,
          password: this.password,
        });
        await logInPassword({
          email: this.email,
          password: this.password,
        });
        await getUserSettings();
        await enterToTheFlow();
        
        let activeSession: any = null;

        if (this.isRegistrationCompleted) {
          const { data }: any = await activeFlow();
          activeSession = data;
        }
      
        if (this.getCurrentFlow) {
          await startFlow(this.getCurrentFlow);
          await this.setPageAndRedirect();
        } else if (activeSession) {
          const { flowVersion, currentPage } = activeSession;
          this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion });
          await startFlow(flowVersion);

          if (currentPage) {
            this.$store.commit('flowStages/setAvailability', currentPage);
            this.$router.push({ name: currentPage });       
          } else {
            await this.setPageAndRedirect();
          }
        } else {
          this.$router.push({ name: getNextPage() });
        }
      }
    } catch (error: any) {
      this.popupError(error.response.data.message);
    } finally {
      this.isPreloaderShow = false;
    }
  }

  async setPageAndRedirect(): Promise<void> {
    await setCurrentStage(getNextPage());
    this.$router.push({ name: getNextPage() });
  }

  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }

  debouncedValidation(fieldName: string): void {
    if (this.timeout) 
      clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.onMountAndTypingValidation(fieldName);
    }, 300);
  }

  mounted(): void {
    if (this.$route.params.error) {
      this.$nextTick(() => {
        this.popupError(this.$route.params.error);
      });
    }
    if (this.email) {
      this.onMountAndTypingValidation('email');
    }
  }
  beforeDestroy(): void {
    if (this.timeout) 
      clearTimeout(this.timeout);
  }

  @Watch('email')
  emailWatcher(): void {
    this.debouncedValidation('email');
  }

  @Watch('password')
  passwordWatcher(): void {
    this.debouncedValidation('password');
  }
}
